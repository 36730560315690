import axios from "axios";

axios.interceptors.request.use((request) => {
  const authorization = localStorage.getItem("authorization");
  const publicToken = localStorage.getItem("authToken");
  if (authorization) {
    request.headers["authorization"] = authorization;
  }
  if (publicToken) {
    request.headers["authorization"] = publicToken;
  }

  // If proxyCompany is set, proxycompanyid and role headers should be set to proxyCompany details
  const proxyCompanyId = localStorage.getItem("proxyCompanyId");
  if (proxyCompanyId) {
    const proxyCompanyAdminRoleId = localStorage.getItem(
      "proxyCompanyAdminRoleId"
    );
    request.headers["proxycompanyid"] = proxyCompanyId;
    request.headers["role"] = proxyCompanyAdminRoleId;
    return request;
  }

  // If proxyCompany is not set, use current role
  const currentRoleId = localStorage.getItem("currentuser-role");
  if (currentRoleId) {
    request.headers["role"] = currentRoleId;
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("currentuser-id");
      localStorage.removeItem("currentuser");
      localStorage.removeItem("currentuser-email");
      localStorage.removeItem("currentuser-role");
      localStorage.removeItem("authorization");

      localStorage.removeItem("collectionCount");
      localStorage.removeItem("selectedTab");
      localStorage.removeItem("user-data");
      localStorage.removeItem("proxyCompanyId");
      localStorage.removeItem("proxyCompanyAdminRoleId");
      localStorage.removeItem("proxyCompanyName");
      localStorage.removeItem("currentLocale");
      
      window.location = "/";
      return Promise.reject(error);
    }
    throw error;
  }
);
