import { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Spin,
  Popconfirm,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  Modal,
  Button,
  ModalHeader,
  NoData,
  Micons,
} from "../../../../../components/customAntd";
import { BREADCRUMB_ADD_NEW_NOTIFICATION } from "../../../../../utils/benefits/constant";
import { getBenefitTitleAndLink } from "../../../../../utils/utils";
import {
  createNotification,
  updateNotification,
  deleteNotification,
  getNotificationsListForBenefit,
} from "../../apiUtils";

import Styles from "./notifications.module.scss";

const { Item } = Form;

const NotificationsModal = ({ isOpen, onCancel, currentRecord }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [list, setList] = useState([]);
  const watchScheduledDate = Form.useWatch("scheduledDate", form);
  const [isEdit, setIsEdit] = useState(false);

  const userCriteriaOptions = useMemo(
    () => [
      {
        label: t("BENEFIT_NOTIFICATION.ALL_USERS"),
        value: "AllUsers",
        disabled: watchScheduledDate?.isAfter(currentRecord?.endDate),
      },
      {
        label: t("BENEFIT_NOTIFICATION.REGISTERED_USERS"),
        value: "RegisteredUsers",
        disabled:
          watchScheduledDate?.isAfter(
            dayjs(currentRecord?.sweepStake?.resultAnnouncementDate)
          ) || watchScheduledDate?.isBefore(currentRecord?.endDate),
      },
      {
        label: t("BENEFIT_NOTIFICATION.WINNERS"),
        value: "Winners",
        // disabled: watchScheduledDate?.isBefore(
        //   dayjs(currentRecord?.sweepStake?.resultAnnouncementDate)
        // ),
      },
    ],
    [watchScheduledDate, t, currentRecord]
  );

  const disabledDate = (current) => {
    return current && current <= dayjs().startOf("day");
  };

  useEffect(() => {
    const getNotifications = async () => {
      setLoadingNotifications(true);
      const response = await getNotificationsListForBenefit(currentRecord.id);
      if (response?.status === 200) {
        setList(response.data);
      } else {
        message.error({
          content: t("BENEFIT_NOTIFICATION.FETCH_NOTIIFICATIONS_ERROR"),
          key: "get-error",
          duration: 2,
        });
      }
      setLoadingNotifications(false);
    };
    if (currentRecord?.id) {
      getNotifications();
    }
    // Set the default value of the User Criteria field based on whether it's a sweepstake
    if (currentRecord?.type !== "sweepstakes") {
      form.setFieldsValue({ userCriteria: "AllUsers" });
    }
  }, [currentRecord]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    data.expiryDate = dayjs(data.expiryDate).set("second", 0);
    const finalData = { ...data, level: "Benefit" };
    finalData.scheduledDate = dayjs(data.scheduledDate)
      .set("second", 0)
      .format(); // Set seconds to zero
    if (!isEdit) delete finalData.id;
    const response = isEdit
      ? await updateNotification(currentRecord.id, finalData.id, finalData)
      : await createNotification(currentRecord.id, finalData);
    if (response?.status === 200) {
      if (isEdit) {
        setList((prev) =>
          prev.map((el) => (el.id === response.data.id ? response.data : el))
        );
      } else {
        setList((prev) => [response.data, ...prev]);
      }
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        key: "error",
        duration: 2,
      });
    }
    setIsLoading(false);
    form.resetFields();
    if (isEdit) {
      setIsEdit(false);
    }
  };

  const handleDelete = async (id) => {
    setLoadingNotifications(true);
    const response = await deleteNotification(currentRecord?.id, id);
    if (response?.status === 200) {
      setList((prev) => prev.filter((el) => el.id !== response.data.id));
      message.success({
        content: "Successfullly deleted notification.",
        key: "success",
        duration: 2,
      });
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
      });
    }
    setLoadingNotifications(false);
  };

  const handleEdit = (notification) => {
    setIsEdit(true);
    form.setFieldsValue({
      ...notification,
      scheduledDate: dayjs(notification.scheduledDate).set("seconds", 0),
    });
  };

  return (
    <Modal
      destroyOnClose
      onCancel={() => {
        setList([]);
        onCancel();
      }}
      open={isOpen}
      centered
      title={
        <ModalHeader
          title={t("NOTIFICATIONS")}
          breadcrumbs={BREADCRUMB_ADD_NEW_NOTIFICATION(
            t,
            ...getBenefitTitleAndLink()
          )}
        />
      }
      width={1200}
      footer={[
        <Button
          buttonType="cancel"
          onClick={() => {
            onCancel();
          }}
          key="cancel-button"
          disabled={isLoading || loadingNotifications}
        >
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={() => {
            form.submit();
          }}
          key="create-button"
          disabled={loadingNotifications}
          loading={isLoading}
        >
          {isEdit ? t("UPDATE") : t("CREATE")}
        </Button>,
      ]}
    >
      <Row gutter={52}>
        <Col md={12}>
          <Form
            form={form}
            onFinish={onSubmit}
            initialValues={{ type: "Toast" }}
            layout="vertical"
            requiredMark={false}
            preserve={false}
            disabled={isLoading || loadingNotifications}
          >
            <Item name="id" hidden></Item>
            <Item
              name="title"
              label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE")} :`}
              rules={[
                {
                  required: true,
                  message: t("PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_VALIDATION"),
                },
              ]}
            >
              <Input
                placeholder={t(
                  "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_PLACEHOLDER"
                )}
              />
            </Item>
            <Item
              name="message"
              label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE")} :`}
              rules={[
                {
                  required: true,
                  message: t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_VALIDATION"
                  ),
                },
              ]}
            >
              <Input
                placeholder={t(
                  "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_PLACEHOLDER"
                )}
              />
            </Item>
            <Item
              name="scheduledDate"
              label={`${t("BENEFIT_NOTIFICATION.SCHEDULED_DATE")}:`}
              rules={[
                {
                  required: true,
                  message: t("BENEFIT_NOTIFICATION.SCHEDULED_DATE_REQUIRED"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  let isDisabled;
                  if (currentRecord?.sweepStake) {
                    isDisabled = current.isAfter(
                      dayjs(currentRecord?.sweepStake?.showUntilDate)
                        .add(1, "day")
                        .startOf("day")
                    );
                  } else {
                    isDisabled =
                      current.isAfter(
                        dayjs(currentRecord?.endDate)
                          .add(1, "day")
                          .startOf("day")
                      ) || current.isBefore(currentRecord?.activeDate);
                  }
                  return (
                    (current &&
                      current < dayjs().subtract(1, "day").endOf("day")) ||
                    isDisabled
                  );
                }}
                disabled={isEdit}
                format="YYYY-MM-DD HH:mm"
                placeholder={t("BENEFIT_NOTIFICATION.SCHEDULED_DATE")}
                showTime={{ format: "HH:mm" }}
                showNow={false}
                popupClassName={Styles["schedule-date-picker"]}
                renderExtraFooter={() => {
                  const currentDate = dayjs();
                  const isAfterEndDate = currentDate.isAfter(
                    dayjs(currentRecord?.endDate)
                  );

                  return (
                    <div className={Styles["button-cont"]}>
                      <Button
                        onClick={() =>
                          form.setFieldValue(
                            "scheduledDate",
                            dayjs(currentRecord?.activeDate).set("second", 0)
                          )
                        }
                        disabled={dayjs().isAfter(
                          dayjs(currentRecord?.activeDate)
                        )}
                      >
                        {t("ACTIVE_DATE")}
                      </Button>
                      {currentRecord?.sweepStake ? (
                        <>
                          <Button
                            onClick={() =>
                              form.setFieldValue(
                                "scheduledDate",
                                dayjs(
                                  currentRecord?.sweepStake
                                    ?.resultAnnouncementDate
                                ).set("second", 0)
                              )
                            }
                            disabled={dayjs().isAfter(
                              dayjs(
                                currentRecord?.sweepStake
                                  ?.resultAnnouncementDate
                              )
                            )}
                          >
                            {t("BENEFIT_NOTIFICATION.RESULT_ANNOUNCEMENT_DATE")}
                          </Button>
                          <Button
                            onClick={() => {
                              const currentDateTime = dayjs()
                                .set("second", 0)
                                .add(1, "minute");
                              form.setFieldValue(
                                "scheduledDate",
                                currentDateTime
                              );
                            }}
                            disabled={dayjs().isAfter(
                              currentRecord?.sweepStake?.showUntilDate
                            )}
                          >
                            {t("NOW")}
                          </Button>
                        </>
                      ) : (
                        <>
                          {/* hide these buttons if the startDate or endDate is null */}
                          {currentRecord?.startDate ? (
                            <Button
                              onClick={() =>
                                form.setFieldValue(
                                  "scheduledDate",
                                  dayjs(currentRecord?.activeDate).set(
                                    "second",
                                    0
                                  )
                                )
                              }
                              disabled={dayjs().isAfter(
                                dayjs(currentRecord?.activeDate)
                              )}
                            >
                              {t("START_DATE")}
                            </Button>
                          ) : null}

                          {currentRecord?.endDate ? (
                            <Button
                              onClick={() =>
                                form.setFieldValue(
                                  "scheduledDate",
                                  dayjs(currentRecord?.endDate).set("second", 0)
                                )
                              }
                              disabled={isAfterEndDate}
                            >
                              {t("END_DATE")}
                            </Button>
                          ) : null}
                        </>
                      )}
                    </div>
                  );
                }}
              />
            </Item>

            <Item name="expiryDate" label={t("EXPIRY_DATE")}>
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                disabled={dayjs(currentRecord?.scheduledDate).isBefore(dayjs())}
                showTime={{ format: "HH:mm" }}
                placeholder={t("EXPIRY_DATE_PLACEHOLDER")}
              />
            </Item>

            <Item
              name="type"
              label={`${t("BENEFIT_NOTIFICATION.TYPE")} :`}
              rules={[
                {
                  required: true,
                  message: t("BENEFIT_NOTIFICATION.TYPE_REQUIRED"),
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: t("BENEFIT_NOTIFICATION.WEB_NOTIFICATION"),
                    value: "Toast",
                  },
                  {
                    label: t("BENEFIT_NOTIFICATION.PUSH_NOTIFICATION"),
                    value: "PushNotification",
                  },
                  { label: t("BOTH"), value: "Both" },
                ]}
              />
            </Item>
            <Item
              label={`${t("BENEFIT_NOTIFICATION.USER_CRITERIA")} :`}
              name="userCriteria"
              rules={[
                {
                  required: true,
                  message: t("BENEFIT_NOTIFICATION.USER_CRITERIA_REQUIRED"),
                },
              ]}
            >
              <Select
                disabled={
                  isEdit ||
                  !watchScheduledDate ||
                  !(currentRecord?.type === "sweepstakes")
                }
                options={userCriteriaOptions}
                placeholder={t(
                  "BENEFIT_NOTIFICATION.USER_CRITERIA_PLACEHOLDER"
                )}
              />
            </Item>
          </Form>
        </Col>
        <Col md={12}>
          <>
            <h3 className={Styles["heading"]}>
              {t("BENEFIT_NOTIFICATION.NOTIFICATIONS_LIST")}
            </h3>
            {loadingNotifications ? (
              <Spin size="small" style={{ marginTop: 30, width: "100%" }} />
            ) : list.length > 0 ? (
              list.map((el) => {
                return (
                  <div className={Styles["notification"]}>
                    <div className={Styles["title-container"]}>
                      <p className={Styles["title"]}>{el.title}</p>
                      {dayjs().isBefore(dayjs(el.scheduledDate)) && (
                        <div>
                          <Micons
                            icon="edit"
                            isHover={false}
                            className={Styles["edit"]}
                            onClick={() => handleEdit(el)}
                          />
                          <Popconfirm
                            title={t(
                              "BENEFIT_NOTIFICATION.DELETE_CONFIRMATION"
                            )}
                            okText={t("YES")}
                            cancelText={t("NO")}
                            onCancel={() => {}}
                            onConfirm={() => {
                              handleDelete(el.id);
                            }}
                          >
                            <Micons
                              icon="delete"
                              isHover={false}
                              className={Styles["delete"]}
                            />
                          </Popconfirm>
                        </div>
                      )}
                    </div>
                    <p className={Styles["message"]}>{el.message}</p>
                    <p>
                      <span className={Styles["schedule"]}>
                        {`${
                          dayjs().isBefore(dayjs(el.scheduledDate))
                            ? t("BENEFIT_NOTIFICATION.SCHEDULED_DATE")
                            : t("BENEFIT_NOTIFICATION.SENT_ON")
                        }:`}
                      </span>
                      <span className={Styles["date"]}>
                        {dayjs(el.scheduledDate).format(
                          "DD MMM YYYY - hh:mm A"
                        )}
                      </span>
                    </p>
                    <p>
                      <span className={Styles["schedule"]}>{`${t(
                        "BENEFIT_NOTIFICATION.USER_CRITERIA"
                      )}: `}</span>
                      <span className={Styles["date"]}>
                        {
                          userCriteriaOptions.find(
                            (item) => item.value === el.userCriteria
                          ).label
                        }
                      </span>
                    </p>
                  </div>
                );
              })
            ) : (
              <NoData />
            )}
          </>
        </Col>
      </Row>
    </Modal>
  );
};

export default NotificationsModal;
