// import Third party and React
import { useEffect, useMemo, useState } from "react";
import {
  Space,
  Row,
  Col,
  Form,
  Skeleton,
  Popconfirm,
  Tooltip,
  Dropdown,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import DefaultImage from "../../../../assets/images/default-img.jpg";
// import utils, API Utils and helper functions
import { BREADCRUMB_DISCOUNT_TOKEN } from "../../../../utils/benefits/constant";
import {
  addBenefitToDb,
  cloneCurrentBenefitinDB,
  deleteBenefitFromDb,
  getAllBenefitsFromDb,
  updateBenefitToDb,
  getBenefitPreviewToken,
} from "../apiUtils";
import { getAllMerchantsFromDb } from "../../merchant/apiUtils";
import { debounce } from "../../../../utils/helper";
import constant from "../../../../config/constant";
import { useAppContext } from "../../../../components/context/app.context";

// import custom components
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Card,
  NoData,
  Modal,
  Micons,
} from "../../../../components/customAntd";
import DiscountCodesFilters from "./filters";
import NewCode from "./newCode";
import NotificationsModal from "../sweepstakes/notifications";

import ReportsModal from "./usageReport";
// import custom Style
import Styles from "./styles/discountCodes.module.scss";

const { Meta } = Card;

const DiscountCodes = () => {
  const [form] = Form.useForm();
  const [displayType, setDisplayType] = useState("grid");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [data, setData] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [usageReportRecord, setUsageReportRecord] = useState(null);
  const [heroImage, setHeroImage] = useState([]);
  const [clone, setClone] = useState(false);
  const [filters, setFilters] = useState({
    filterCondition: { benefitType: "coupon" },
  });
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const { t } = useTranslation();
  const { currentLocale, currentUserData } = useAppContext();

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        dataIndex: "previewResourceUrl",
        width: 100,
        render: (_, record) => (
          <img
            src={
              record?.locales?.find?.(
                (locale) => locale.localeId === currentLocale
              )?.previewResourceUrl ??
              record?.previewResourceUrl ??
              DefaultImage
            }
            alt="Preview"
            height={50}
            width={50}
            className="table-data-image"
            onClick={() => handleEditClick(record)}
          />
        ),
      },
      {
        title: t("ID"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("DISCOUNT_CODES.CODE_NAME"),
        dataIndex: "title",
        key: "title",
        width: "15%",
        sorter: (a, b) => a.title.length - b.title.length,
        render: (_, { title, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.title ?? title,
      },
      {
        title: t("MERCHANT.BREADCRUMB").toUpperCase(),
        dataIndex: "merchant",
        key: "merchant",
        render: (_, { merchant }) =>
          merchant?.locales?.find?.(
            (locale) => locale.localeId === currentLocale
          )?.name ?? merchant.name,
      },
      {
        title: t("ACTIVE_DATE").toUpperCase(),
        key: "activeDate",
        dateIndex: "activeDate",
        render: (_, { activeDate }) => (
          <>
            <Tag isDisabled>
              {dayjs(activeDate).format("MM/DD/YYYY hh:mm A")}
            </Tag>
          </>
        ),
      },
      {
        title: t("REDEEMABLE_PERIOD").toUpperCase(),
        key: "startDate",
        dataIndex: "startDate",
        render: (_, { startDate, endDate }) => (
          <>
            {
              <>
                <Tag isDisabled>
                  {dayjs(startDate).format("MM/DD/YYYY hh:mm A")}
                </Tag>
                <span>-</span>
                <Tag isDisabled style={{ marginLeft: 5 }}>
                  {dayjs(endDate).format("MM/DD/YYYY hh:mm A")}
                </Tag>
              </>
            }
          </>
        ),
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "13%",
        render: (_, record) => (
          <Space size="middle">
            <Button type="link">
              <Tooltip title={t("EDIT")}>
                <Micons
                  icon="edit"
                  className="table-action-icon"
                  onClick={() => handleEditClick(record)}
                />
              </Tooltip>
            </Button>
            <Popconfirm
              title={t("DISCOUNT_CODES.DELETE_COUPON_CONFIRM")}
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => {}}
              okText={t("YES")}
              cancelText={t("NO")}
              placement="topRight"
              arrowPointAtCenter
            >
              <Button type="link">
                <Tooltip title={t("DELETE")}>
                  <Micons icon="delete" className="table-action-icon" />
                </Tooltip>
              </Button>
            </Popconfirm>
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: "clone",
                    label: t("CLONE"),
                    icon: (
                      <Micons
                        icon="content_copy"
                        className="table-action-icon"
                      />
                    ),
                  },
                  {
                    key: "preview",
                    label: t("PREVIEW"),
                    icon: (
                      <Micons icon="visibility" className="table-action-icon" />
                    ),
                  },
                  {
                    key: "notifications",
                    label: t("NOTIFICATIONS"),
                    icon: (
                      <Micons
                        icon="notifications"
                        className="table-action-icon"
                      />
                    ),
                  },
                ],
                onClick: (el) => {
                  const { key } = el;
                  if (key === "clone") {
                    handleCloneClick(record);
                  } else if (key === "preview") {
                    handlePreviewClick(record);
                  } else if (el.key === "notifications") {
                    setCurrentRecord(record);
                    setIsNotificationsModalOpen(true);
                  }
                },
              }}
              style={{ width: 200 }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Micons icon="more_vert" className="table-action-icon" />
              </a>
            </Dropdown>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  // Get all discount codes initially
  useEffect(() => {
    const fetchCodes = async () => {
      setIsLoading(true);
      const getAllCodes = await getAllBenefitsFromDb(filters);
      if (getAllCodes?.status === 200) {
        setData(getAllCodes.data);
      }
      setIsLoading(false);
    };
    fetchCodes();
    if (clone) setClone(false);
  }, [filters, clone]);

  // get All merchants initially
  useEffect(() => {
    const fetchMerchants = async () => {
      const getAllMerchants = await getAllMerchantsFromDb();
      if (getAllMerchants?.status === 200) {
        setMerchantData(() =>
          getAllMerchants.data.map((item) => {
            return {
              label: item.name,
              value: item.name,
              id: item.id,
              isSelected: false,
              locales: item.locales,
              categoryId: item.categoryId,
            };
          })
        );
      }
    };
    fetchMerchants();
  }, []);

  // Switching between a list view and a grid view.
  const onViewChange = () => {
    setDisplayType(displayType === "list" ? "grid" : "list");
  };

  // Handler for the cancel button
  const handleCancleClick = () => {
    form.resetFields();
    setIsSubmitting(false);
    setIsDataLoading(false);
    setIsOpen(false);
    setIsEdit(false);
    setEditData(null);
  };

  // Submitting the final form after it has passed all of the validation checks
  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);

    data?.locales?.forEach((locale) => {
      locale.previewResourceUrl = heroImage?.[0]?.resourceUrl;
    });

    const finalData = {
      title: data.code_name,
      shortDescription: data.code_short_description,
      description: data.code_description,
      disclaimer: data.disclaimer,
      discountType: data.discount_type,
      discountPrice: Number(data.discount_amount),
      unlimitedRedemption: data.unlimited_use === "yes",
      redemptionPerPass:
        data.unlimited_use === "no" ? Number(data.use_limited_pass) : 0,
      redemptionTotalLimit:
        data.unlimited_use === "no" ? Number(data.use_limited_total) : 0,
      couponCode: data.code,
      startDate: dayjs(data.redeemable_period[0]).set("seconds", 0).format(),
      endDate: dayjs(data.redeemable_period[1]).set("seconds", 0).format(),
      status: data.status,
      merchantId: merchantData.filter((item) => item.isSelected)[0]?.id,
      type: "coupon",
      previewResourceUrl: heroImage[0].resourceUrl,
      resourceUrl: heroImage[0].resourceUrl,
      frequency: data.frequency,
      frequencyLimit: data.frequency_limit,
      locales: data.locales,
      defaultLocaleId: data.defaultLocaleId,
      membershipCriteria: data.membershipCriteria,
      showImagesOnly: data.show_images_only,
      partnerRedirectUrl: data.partner_redirecturl,
      activeDate: dayjs(data.active_date).set("seconds", 0).format(),
      categoryId: merchantData.filter((item) => item.isSelected)[0]?.categoryId,
      redeemType: data.redeemType,
      uniqueTransactionId: data.uniqueTransactionId,
    };
    if (isEdit) {
      finalData.locales.forEach((locale) => {
        delete locale.benefitId;
      });
    }
    const createNewCode = isEdit
      ? await updateBenefitToDb(finalData, data.id)
      : await addBenefitToDb(finalData);
    if (createNewCode?.status === 200) {
      if (isEdit) {
        setData((prev) =>
          prev.map((item) => (item.id === data.id ? createNewCode.data : item))
        );
      } else {
        setData((prev) => [createNewCode.data, ...prev]);
      }
      handleCancleClick();
    }
    setIsSubmitting(false);
  };

  // Handler for the edit 'discount code' button
  const handleEditClick = (record) => {
    const finalData = {
      id: record.id,
      code_name: record.title,
      code_description: record.description,
      code_short_description: record.shortDescription,
      disclaimer: record.disclaimer,
      discount_type: record.discountType,
      discount_amount: record.discountPrice,
      type: record.type,
      unlimited_use: record.unlimitedRedemption ? "yes" : "no",
      use_limited_pass: !record.unlimitedRedemption
        ? record.redemptionPerPass
        : 0,
      use_limited_total: !record.unlimitedRedemption
        ? record.redemptionTotalLimit
        : 0,
      code: record.couponCode,
      redeemable_period: [
        record.startDate
          ? dayjs(record.startDate).set("seconds", 0)
          : dayjs().set("seconds", 0),
        record.endDate
          ? dayjs(record.endDate).set("seconds", 0)
          : dayjs().add(1, "month").set("seconds", 0),
      ],
      merchant_name: {
        label: record?.merchant?.name,
        value: record?.merchant?.name,
        id: record?.merchant?.id,
      },
      status: record.status,
      frequency: record.frequency,
      frequency_limit: record.frequencyLimit,
      locales: record.locales,
      defaultLocaleId: record.defaultLocaleId,
      membershipCriteria: record.membershipCriteria,
      show_images_only: record.showImagesOnly,
      partner_redirecturl: record.partnerRedirectUrl,
      active_date: record.activeDate
        ? dayjs(record.activeDate).set("seconds", 0)
        : dayjs().set("seconds", 0),
      redeemType: record.redeemType,
      code_type:
        record.redeemType === "CODE" && record.uniqueTransactionId
          ? "unique"
          : "random",
      uniqueTransactionId: record.uniqueTransactionId,
    };

    if (record.previewResourceUrl) {
      setHeroImage([
        {
          name: record.previewResourceUrl.split("/").pop(),
          resourceUrl: record.previewResourceUrl,
          type: "heroImage",
        },
      ]);
    }
    setMerchantData((prev) =>
      prev.map((item) => {
        return {
          ...item,
          isSelected: item.id === record.merchant.id,
        };
      })
    );
    setIsEdit(true);
    setEditData(record);
    form.setFieldsValue(finalData);
    setIsOpen(true);
  };

  // Handler for the delete 'discount code' button
  const handleDelete = async (recordId) => {
    const deletedCode = await deleteBenefitFromDb(recordId);
    if (deletedCode?.status === 200) {
      setData((prev) => prev.filter((item) => item.id !== deletedCode.data.id));
    }
  };

  // Handler for the delete 'discount code' button
  const handleCloneClick = async (record) => {
    const clonedCode = await cloneCurrentBenefitinDB(record.id);
    if (clonedCode?.status === 200) {
      setClone(true);
    }
  };

  // Handler for the Preview  button
  const handlePreviewClick = async (record) => {
    setPreviewUrl(null);
    const tokenCode = await getBenefitPreviewToken(record.id);
    if (tokenCode?.status === 200) {
      const { companyId } = currentUserData;
      // const proxyCompanyId = localStorage.getItem("proxyCompanyId")
      console.log(
        "constant.config.STOREFRONT_SITE_URL",
        constant.config.STOREFRONT_SITE_URL,
        constant.config.STOREFRONT_SITE_TITLES[companyId],
        companyId
      );
      const url = `${constant.config.STOREFRONT_SITE_URL}${constant.config.STOREFRONT_SITE_TITLES[companyId]}/benefits/${record.id}?token=${tokenCode.data.token}`;
      setPreviewUrl(url);
      setShowPreview(true);
    }
  };

  // Handler for the search input
  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (searchTerm !== "") {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            search: searchTerm,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.search;
      setFilters(filtersCopy);
    }
  });

  // Handler for the filter select
  const handleFilterByChange = (type, value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            [`${type}`]: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.status;
      setFilters(filtersCopy);
    }
  };

  const renderPreview = () => {
    return (
      <object
        data={previewUrl}
        width="428px"
        height="926px"
        alt="Benefit Preview"
        title="Benefit Preview"
        style={{ paddingTop: "25px" }}
      />
    );
  };

  return (
    <>
      <PageHeader
        title={t("BENEFIT_TYPES.DISCOUNT_CODES.TITLE")}
        breadcrumbs={BREADCRUMB_DISCOUNT_TOKEN(t)}
        extra={
          <div style={{ display: "flex" }}>
            {/* <Button
              className="view-merchant"
              type="default"
              icon={<MerchantIcon />}
              onClick={() => setShowMerchant(true)}
            >
              {t("MERCHANT.VIEW")}
            </Button> */}
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsOpen(true);
                setHeroImage([]);
              }}
            >
              {t("DISCOUNT_CODES.NEW_CODE")}
            </Button>
          </div>
        }
      />
      <DiscountCodesFilters
        onViewChange={onViewChange}
        type={displayType}
        onSearch={handleSearch}
        onFilterByChange={handleFilterByChange}
        merchantOptions={merchantData.map((el) => {
          return {
            label:
              el?.locales?.find?.((locale) => locale.localeId === currentLocale)
                ?.name ?? el.label,
            value: el.id,
          };
        })}
      />
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {data.length === 0 ? (
            <NoData />
          ) : (
            <>
              {displayType === "list" ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                  locale={{
                    triggerDesc: t("SORT_DESCENDING"),
                    triggerAsc: t("SORT_ASCENDING"),
                    cancelSort: t("CANCEL_SORT"),
                  }}
                />
              ) : (
                <Row gutter={[25, 25]}>
                  {data.map((item) => {
                    const currentLocaleData = item?.locales?.find(
                      (locale) => locale.localeId === currentLocale
                    );
                    return (
                      <Col
                        xs={24}
                        md={12}
                        lg={6}
                        key={`${item.title}-${item.couponCode}-${item.id}}`}
                      >
                        <Card
                          isActiveDate
                          // className={Styles["discount-card"]}
                          cover={
                            <img
                              alt={item.title}
                              src={
                                currentLocaleData?.previewResourceUrl ??
                                item.previewResourceUrl ??
                                DefaultImage
                              }
                              height="200px"
                              width="100%"
                              className="pointer"
                              onClick={() => handleEditClick(item)}
                            />
                          }
                          actions={[
                            <Tooltip title={t("REPORTS")}>
                              <Button
                                type="link"
                                onClick={() => setUsageReportRecord(item)}
                              >
                                <Micons
                                  icon="assessment"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>,
                            <Popconfirm
                              title={t("DISCOUNT_CODES.DELETE_COUPON_CONFIRM")}
                              onConfirm={() => handleDelete(item.id)}
                              onCancel={() => {}}
                              okText={t("YES")}
                              cancelText={t("NO")}
                            >
                              <Tooltip title={t("DELETE")}>
                                <Micons
                                  icon="delete"
                                  className="table-action-icon"
                                />
                              </Tooltip>
                            </Popconfirm>,
                            <Tooltip title={t("EDIT")}>
                              <Button
                                type="link"
                                onClick={() => handleEditClick(item)}
                              >
                                <Micons
                                  icon="edit"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>,
                            <Tooltip title={t("PREVIEW")}>
                              <Button
                                type="link"
                                onClick={() => handlePreviewClick(item)}
                              >
                                <Micons
                                  icon="visibility"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>,
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: "notifications",
                                    label: t("NOTIFICATIONS"),
                                    icon: (
                                      <Micons
                                        icon="notifications"
                                        className="table-action-icon"
                                      />
                                    ),
                                  },
                                  {
                                    key: "clone",
                                    label: t("DISCOUNT_CODES.CLONE"),
                                    icon: (
                                      <Micons
                                        icon="content_copy"
                                        className="table-action-icon"
                                      />
                                    ),
                                  },
                                ],
                                onClick: (el) => {
                                  if (el.key === "notifications") {
                                    setCurrentRecord(item);
                                    setIsNotificationsModalOpen(true);
                                  } else if (el.key === "clone") {
                                    handleCloneClick(item);
                                  }
                                },
                              }}
                              style={{ width: 200 }}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Micons
                                  icon="more_vert"
                                  className="table-action-icon"
                                />
                              </a>
                            </Dropdown>,
                          ]}
                        >
                          <p style={{ marginBottom: 5 }}>{`${t("ID")}: ${
                            item.id
                          }`}</p>
                          <div className="active-date">
                            <strong>{`${t(
                              "SWEEPSTAKES.ACTIVE_DATE_COLUMN"
                            )}:`}</strong>
                            {dayjs(item.activeDate).format("MM/DD/YYYY")}
                          </div>
                          <Tag className={Styles.tag} isDisabled>
                            {`${dayjs(item.startDate).format(
                              "MM/DD/YYYY"
                            )} - ${dayjs(item.endDate).format("MM/DD/YYYY")}`}
                          </Tag>
                          <Meta
                            title={currentLocaleData?.title ?? item.title}
                            description={item.couponCode}
                          />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </>
          )}
        </>
      )}

      <NewCode
        form={form}
        isOpen={isOpen}
        onCancel={handleCancleClick}
        onSubmitClick={() => form.submit()}
        onSubmit={handleFormSubmit}
        loading={isSubmitting}
        isDataLoading={isDataLoading}
        isEdit={isEdit}
        editData={editData}
        merchantData={merchantData}
        setMerchantData={setMerchantData}
        setHeroImage={setHeroImage}
        heroImage={heroImage}
      />
      {/* <Modal
        title={
          <PageHeader
            title={t("MERCHANT.TITLE")}
            breadcrumbs={BREADCRUMB_MERCHANTS(t)}
            wrapperStyle={{ marginBottom: 0 }}
            headStyle={{ marginTop: 25 }}
            extra={
              <Button
                icon={<PlusOutlined />}
                onClick={() => setShowCreateMerchant(true)}
              >
                {t("MERCHANT.NEW")}
              </Button>
            }
          />
        }
        width={1200}
        centered
        destroyOnClose
        maskClosable={false}
        open={showMerchant}
        onCancel={() => {
          setShowMerchant(false);
        }}
        onOk={() => {
          setShowMerchant(false);
        }}
        cancelText={t("CANCEL")}
        okText={t("OK")}
      >
        <MerchantPage
          showCreateMerchant={showCreateMerchant}
          setShowCreateMerchant={setShowCreateMerchant}
        />
      </Modal> */}
      <Modal
        width={500}
        height={1370}
        destroyOnClose
        open={showPreview}
        onCancel={() => {
          setShowPreview(false);
        }}
        onOk={() => {
          setShowPreview(false);
        }}
        cancelText={t("CANCEL")}
        okText={t("OK")}
      >
        {renderPreview()}
      </Modal>
      <NotificationsModal
        isOpen={isNotificationsModalOpen}
        currentRecord={currentRecord}
        onCancel={() => {
          setCurrentRecord(null);
          setIsNotificationsModalOpen(false);
        }}
      />
      <ReportsModal
        currentRecord={usageReportRecord}
        onCancel={() => setUsageReportRecord(null)}
      />
    </>
  );
};

export default DiscountCodes;
