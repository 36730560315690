import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Spin } from "antd";

import { Modal, ModalHeader } from "../../../../../components/customAntd";
import { fetchBenefitUsageReport } from "../../apiUtils";
import { BREADCRUMB_BENEFIT_USAGE_REPORT } from "../../../../../utils/benefits/constant";
import { getBenefitTitleAndLink } from "../../../../../utils/utils";

const ReportsModal = ({ currentRecord, onCancel }) => {
  const [reportUrl, setReportUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchReportUrl = async () => {
      if (currentRecord?.id) {
        setIsLoading(true);
        const getReports = await fetchBenefitUsageReport(currentRecord?.id);
        if (getReports?.status === 200) {
          setReportUrl(getReports.data.url);
        } else {
          message.error({
            content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
            duration: 3,
            key: "error",
          });
        }
        setIsLoading(false);
      }
    };

    fetchReportUrl();
  }, [currentRecord]);

  return (
    <Modal
    title={
        <ModalHeader
          title={t("REPORTS")}
          breadcrumbs={BREADCRUMB_BENEFIT_USAGE_REPORT(
            t,
            ...getBenefitTitleAndLink(t, currentRecord)
          )}
        />
      }

      open={!!currentRecord}
      onCancel={onCancel}
      width={1200}
      footer={null}
      destroyOnClose
    >
      {isLoading && <div className="spin-wrapper" style={{height: 400}}><Spin/></div>}
      {reportUrl && (
        <iframe
          src={reportUrl}
          frameBorder="0"
          width="100%"
          height="1400px"
          allowTransparency
        />
      )}
    </Modal>
  );
};

export default ReportsModal;
