import { ROLE_PERMISSIONS } from "../utils/constants";
import { Micons } from "../components/customAntd";
import { ScanOutlined } from "@ant-design/icons";
import Homepage from "../pages/homepage";
import Scan from "../pages/scan/scan.js";
import {
  Announcement,
  News,
  MerchantCommunications,
  Notification,
  PressRelease,
  Program,
  ProgramDetails,
  TierBenefits,
  TierDetails,
  Benefits,
  DiscountCodes,
  Sweepstakes,
  GatedVideo,
  GatedStreams,
  GatedAudio,
  GatedEbook,
  Survey,
  StorefrontBuilder,
  SelectProgram,
  BuildStoreFront,
  StorefrontFAQ,
  Merchants,
  MerchantForm,
  EditTheme,
} from "../pages/launchpad";

import Admin from "../pages/admin";
import UserProfile from "../pages/profile";
import { AuditHistory } from "../components/auditHistory";
import UserIsAdmin from "../components/require.auth/user.isadmin";
import Categories from "../pages/launchpad/categories";
import MerchantReports from "../pages/launchpad/merchant/reports/index.jsx";
import UploadUsers from "../pages/launchpad/merchant/uploadUsers/index.jsx";

export const routesConfig = (type, t = () => {}) =>
  type === "Merchant"
    ? [
        {
          path: "/profile",
          component: <MerchantForm />,
          permissions: [],
          menu: {
            key: "profile",
            icon: (
              <Micons
                isHover={false}
                icon="supervised_user_circle"
                style={{ fontSize: 24 }}
              />
            ),
            label: t("LEFT_MENU.PROFILE"),
          },
        },
        {
          path: "/users",
          component: <UploadUsers />,
          permissions: [],
          menu: {
            key: "users",
            icon: (
              <Micons
                isHover={false}
                icon="people_outline"
                style={{ fontSize: 24 }}
              />
            ),
            label: t("LEFT_MENU.USERS"),
          },
        },
        {
          path: "/reports",
          component: <MerchantReports />,
          permissions: [],
          menu: {
            key: "reports",
            icon: (
              <Micons
                isHover={false}
                icon="analytics"
                style={{ fontSize: 24 }}
              />
            ),
            label: t("LEFT_MENU.REPORTS"),
          },
        },
      ]
    : [
        {
          path: "/home",
          component: <Homepage />,
          permissions: [],
          menu: {
            key: "home",
            icon: <Micons isHover={false} icon="dashboard" />,
            label: t("LEFT_MENU.DASHBOARD"),
          },
        },
        {
          path: "/categories",
          component: <Categories />,
          permissions: [],
          menu: {
            key: "categories",
            icon: <Micons isHover={false} icon="category" />,
            label: t("LEFT_MENU.CATEGORIES"),
          },
        },
        {
          path: "/announcement",
          component: <Announcement />,
          permissions: [
            ROLE_PERMISSIONS.VIEWBENEFITNOTIFICATIONS,
            ROLE_PERMISSIONS.MANAGEBENEFITNOTIFICATIONS,
          ],
          menu: {
            key: "announcement",
            icon: (
              <Micons
                isHover={false}
                icon="campaign"
                style={{ fontSize: 24 }}
              />
            ),
            label: t("LEFT_MENU.ANNOUNCEMENT"),
          },
          children: [
            {
              path: "/announcement/notifications",
              component: <Notification />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITNOTIFICATIONS],
            },
            {
              path: "/announcement/pressrelease",
              component: <PressRelease />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITNOTIFICATIONS],
            },
            {
              path: "/announcement/news",
              component: <News />,
              permissions: [
                ROLE_PERMISSIONS.VIEWBENEFITNOTIFICATIONS,
                ROLE_PERMISSIONS.MANAGEBENEFITNOTIFICATIONS,
              ],
            },
            {
              path: "/announcement/merchant-communications",
              component: <MerchantCommunications />,
              permissions: [
                ROLE_PERMISSIONS.VIEWBENEFITNOTIFICATIONS,
                ROLE_PERMISSIONS.MANAGEBENEFITNOTIFICATIONS,
              ],
            },
          ],
        },
        // {
        //   path: "/launchpad",
        //   component: <Launchpad />,
        //   permissions: [
        //     ROLE_PERMISSIONS.VIEWPROGRAMS,
        //     ROLE_PERMISSIONS.VIEWBENEFITS,
        //     ROLE_PERMISSIONS.VIEWSTOREFRONT,
        //   ],
        //   menu: {
        //     key: "launchpad",
        //     icon: <Micons icon="rocket_launch" isHover={false} />,
        //     label: "Launchpad",
        //   },
        //   children: [
        {
          path: "/programs",
          component: <Program />,
          permissions: [ROLE_PERMISSIONS.VIEWPROGRAMS],
          menu: {
            key: "programs",
            icon: (
              <Micons
                isHover={false}
                icon="list_alt"
                style={{ fontSize: 24 }}
              />
            ),
            label: t("LEFT_MENU.PROGRAMS"),
          },
          children: [
            {
              path: "/programs/:id",
              component: <ProgramDetails />,
              permissions: [ROLE_PERMISSIONS.VIEWPROGRAMS],
            },
            {
              path: "/programs/:programId/tiers/benefits",
              component: <TierBenefits />,
              permissions: [ROLE_PERMISSIONS.VIEWPROGRAMS],
            },
            {
              path: "/programs/:programId/tiers/:id/details",
              component: <TierDetails />,
              permissions: [ROLE_PERMISSIONS.VIEWPROGRAMS],
            },
          ],
        },
        {
          path: "/benefits",
          component: <Benefits />,
          permissions: [
            ROLE_PERMISSIONS.VIEWBENEFITS,
            ROLE_PERMISSIONS.MANAGEBENEFITS,
          ],
          menu: {
            key: "benefits",
            icon: (
              <Micons isHover={false} icon="loyalty" style={{ fontSize: 24 }} />
            ),
            label: t("LEFT_MENU.BENEFITS"),
          },
          children: [
            {
              path: "/benefits/sweepstakes",
              component: <Sweepstakes />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITS],
              key: "benefits",
            },
            {
              path: "/benefits/discount-codes",
              component: <DiscountCodes />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITS],
              key: "benefits",
            },
            {
              path: "/benefits/gated-video",
              component: <GatedVideo />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITS],
              key: "benefits",
            },
            {
              path: "/benefits/gated-audio",
              component: <GatedAudio />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITS],
              key: "benefits",
            },
            {
              path: "/benefits/gated-streams",
              component: <GatedStreams />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITS],
              key: "benefits",
            },
            {
              path: "/benefits/gated-ebook",
              component: <GatedEbook />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITS],
              key: "benefits",
            },
            {
              path: "/benefits/survey",
              component: <Survey />,
              permissions: [ROLE_PERMISSIONS.VIEWBENEFITS],
              key: "benefits",
            },
          ],
        },
        {
          path: "/storefront-builder",
          component: <StorefrontBuilder />,
          permissions: [
            ROLE_PERMISSIONS.VIEWSTOREFRONT,
            ROLE_PERMISSIONS.MANAGESTOREFRONT,
          ],
          menu: {
            key: "storefront-builder",
            icon: (
              <Micons
                isHover={false}
                icon="storefront"
                style={{ fontSize: 24 }}
              />
            ),
            label: t("LEFT_MENU.STOREFRONT_BUILDER"),
          },
          children: [
            {
              path: "/storefront-builder/select-program",
              component: <SelectProgram />,
              permissions: [
                ROLE_PERMISSIONS.VIEWSTOREFRONT,
                ROLE_PERMISSIONS.MANAGESTOREFRONT,
              ],
            },
            {
              path: "/storefront-builder/create-storefront",
              component: <BuildStoreFront />,
              permissions: [
                ROLE_PERMISSIONS.VIEWSTOREFRONT,
                ROLE_PERMISSIONS.MANAGESTOREFRONT,
              ],
            },
            {
              path: "/storefront-builder/:id/faqs",
              component: <StorefrontFAQ />,
              permissions: [
                ROLE_PERMISSIONS.VIEWSTOREFRONT,
                ROLE_PERMISSIONS.MANAGESTOREFRONT,
              ],
            },
            {
              path: "/storefront-builder/edit-theme",
              component: <EditTheme />,
              permissions: [
                ROLE_PERMISSIONS.VIEWSTOREFRONT,
                ROLE_PERMISSIONS.MANAGESTOREFRONT,
              ],
            },
          ],
        },
        {
          path: "/merchants",
          component: <Merchants />,
          permissions: [],
          menu: {
            key: "merchants",
            icon: (
              <Micons
                isHover={false}
                icon="supervised_user_circle"
                style={{ fontSize: 24 }}
              />
            ),
            label: t("LEFT_MENU.MERCHANTS"),
          },
        },
        {
          path: "/scan",
          component: <Scan />,
          permissions: [ROLE_PERMISSIONS.SCANNER],
          menu: {
            key: "scan",
            icon: <ScanOutlined style={{ fontSize: 24 }} />,
            label: t("LEFT_MENU.SCAN"),
          },
        },
        {
          path: "/user-profile",
          component: <UserProfile />,
          permissions: [ROLE_PERMISSIONS.VIEWUSERS],
          menu: {
            key: "user-profile",
            icon: <Micons icon="settings" isHover={false} />,
            label: t("LEFT_MENU.SETTINGS"),
          },
        },
        {
          path: "/admin",
          component: <Admin />,
          permissions: [ROLE_PERMISSIONS.MANAGEUSERS],
        },
        {
          path: "/user-audit-history/:id",
          component: (
            <UserIsAdmin>
              <AuditHistory adminView />
            </UserIsAdmin>
          ),
          permissions: [ROLE_PERMISSIONS.VIEWUSERS],
        },
      ];
