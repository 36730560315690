export const BREADCRUMB_BENEFITS = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
  },
];

export const BREADCRUMB_DISCOUNT_TOKEN = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("BENEFIT_TYPES.DISCOUNT_CODES.TITLE"),
  },
];

export const BREADCRUMB_SWEEPSTAKES_TOKEN = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("BENEFIT_TYPES.SWEEPSTAKES.TITLE"),
  },
];

export const BREADCRUMB_SWEEPSTAKES_REGISTRATIONS = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("BENEFIT_TYPES.SWEEPSTAKES.TITLE"),
    link: "/launchpad/benefits/sweepstakes",
  },
  {
    title: t("REGISTRATIONS"),
  },
];

export const BREADCRUMB_ADD_NEW_DISCOUNT_TOKEN = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("BENEFIT_TYPES.DISCOUNT_CODES.TITLE"),
    link: "/launchpad/benefits/discount-codes",
  },
  {
    title: t("DISCOUNT_CODES.NEW_CODE"),
  },
];

export const BREADCRUMB_TOKEN_GATED_STREAMS = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("STREAMS.HEADER"),
  },
];

export const BREADCRUMB_TOKEN_GATED_VIDEO = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("VIDEO.HEADER"),
  },
];

export const BREADCRUMB_TOKEN_GATED_VIDEO_ADD_NEW = (isEdit, t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("VIDEO.HEADER"),
    link: "/launchpad/benefits/gated-video",
  },
  {
    title: isEdit ? t("VIDEO.EDIT") : t("VIDEO.ADD"),
  },
];

export const BREADCRUMB_TOKEN_GATED_AUDIO = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("AUDIO.HEADER"),
  },
];

export const BREADCRUMB_TOKEN_GATED_AUDIO_ADD_NEW = (isEdit, t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("AUDIO.HEADER"),
    link: "/launchpad/benefits/gated-audio",
  },
  {
    title: isEdit ? t("AUDIO.EDIT") : t("AUDIO.ADD"),
  },
];
export const BREADCRUMB_TOKEN_GATED_EBOOK = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("EBOOK.BREADCRUMB"),
  },
];

export const BREADCRUMB_ADD_NEW_TOKEN_GATED_EBOOK = (isEdit, t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("EBOOK.BREADCRUMB"),
    link: "/launchpad/benefits/gated-ebook",
  },
  {
    title: isEdit ? t("EBOOK.EDIT") : t("EBOOK.ADD"),
  },
];

export const BREADCRUMB_SURVEY = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("SURVEY.BREADCRUMB"),
  },
];

export const BREADCRUMB_ADD_NEW_SURVEY = (isEdit, t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("SURVEY.BREADCRUMB"),
    link: "/launchpad/benefits/survey",
  },
  {
    title: isEdit ? t("SURVEY.EDIT") : t("SURVEY.ADD"),
  },
];

export const BREADCRUMB_ADD_NEW_SWEEPSTAKE = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: t("BENEFIT_TYPES.SWEEPSTAKES.TITLE"),
    link: "/launchpad/benefits/sweepstakes",
  },
  {
    title: t("SWEEPSTAKES.NEW"),
  },
];

export const BREADCRUMB_ADD_NEW_NOTIFICATION = (t, benefitTitle, link) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: benefitTitle,
    link: `/launchpad/benefits/${link}`,
  },
  {
    title: t("NOTIFICATIONS"),
  },
];

export const BREADCRUMB_BENEFIT_USAGE_REPORT = (t, benefitTitle, link) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("BENEFITS"),
    link: "/launchpad/benefits",
  },
  {
    title: benefitTitle,
    link: `/launchpad/benefits/${link}`,
  },
  {
    title: t("REPORTS"),
  },
];

export const BENEFITS_LIST = (t) => [
  {
    id: 1,
    name: t("BENEFIT_TYPES.DISCOUNT_CODES.TITLE"),
    type: "coupon",
  },
  {
    id: 2,
    name: t("BENEFIT_TYPES.SWEEPSTAKES.TITLE"),
    type: "sweepstakes",
  },
  {
    id: 3,
    name: t("BENEFIT_TYPES.VIDEO.TITLE"),
    type: "video",
  },
  {
    id: 4,
    name: t("BENEFIT_TYPES.STREAMS.TITLE"),
    type: "streams",
  },
  {
    id: 5,
    name: t("BENEFIT_TYPES.AUDIO.TITLE"),
    type: "audio",
  },
  {
    id: 6,
    name: t("BENEFIT_TYPES.DIGITAL_BOOK.TITLE"),
    type: "digitalbook",
  },
  {
    id: 7,
    name: t("BENEFIT_TYPES.SURVEY.TITLE"),
    type: "survey",
  },
];
