import video from "../assets/images/video.svg";
import music from "../assets/images/music.svg";
import discount from "../assets/images/discount.svg";
import sweepstakesIcon from "../assets/images/sweepstakes-icon.png";

import {
  verifyEmailEnglish,
  passwordResetEnglish,
  verifyEmailJapanese,
  passwordResetJapanese,
} from "./storeFront/emailTemplates";
import constant from "../config/constant";

export const truncateWithEllipses = (contractId, startingText, endingText) => {
  return `${contractId.substring(0, startingText)}...${contractId.slice(
    -endingText
  )}`;
};

export const sortObjectList = (arr, field) => {
  const sortArray = [...arr];
  sortArray.sort((a, b) =>
    a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
  );

  return sortArray;
};

export const scanURLs = {
  eth: constant.config.ETHEREUM_SCAN_URL,
  goerli: constant.config.GOERLI_ETHEREUM_SCAN_URL,
  polygon: constant.config.POLYGON_SCAN_URL,
  mumbai: constant.config.MUMBAI_POLYGON_SCAN_URL,
  arbitrum: constant.config.ARBITRUM_SCAN_URL,
  "arbitrum-testnet": constant.config.ARBITRUM_TESTNET_URL,
  avalanche: constant.config.AVALANCHE_SCAN_URL,
  "avalanche-testnet": constant.config.AVALANCHE_TESTNET_URL,
  bsc: constant.config.BSC_SCAN_URL,
  "bsc-testnet": constant.config.BSC_TESTNET_URL,
  fantom: constant.config.FANTOM_SCAN_URL,
  "fantom-testnet": constant.config.FANTOM_TESTNET_URL,
  optimism: constant.config.OPTIMISM_SCAN_URL,
  "optimism-testnet": constant.config.OPTIMISM_TESTNET_URL,
};

export const imageFileSizes = {
  profileImage: 1,
  logoImage: 1,
  bannerImage: 100,
  passImage: 100,
};

export const getTranslatedBenefitType = (type, t) => {
  if (type === "audio") {
    return { label: t("SONG"), icon: music, color: "purple" };
  } else if (type === "video") {
    return { label: t("SINGLE_VIDEO"), icon: video, color: "magenta" };
  } else if (type === "coupon") {
    return { label: t("DISCOUNT_CODE"), icon: discount, color: "orange" };
  } else if (type === "digitalbook") {
    return { label: t("DIGITAL_BOOK"), icon: discount, color: "gold" };
  } else if (type === "streams") {
    return {
      label: t("ADD_BENEFITS_MODAL.STREAMS"),
      icon: video,
      color: "red",
    };
  } else if (type === "sweepstakes") {
    return {
      label: t("ADD_BENEFITS_MODAL.SWEEPSTAKE"),
      icon: sweepstakesIcon,
      color: "blue",
    };
  } else if (type === "survey") {
    return {
      label: t("ADD_BENEFITS_MODAL.SURVEY"),
      icon: sweepstakesIcon,
      color: "blue",
    };
  }
};

export const getEmailTemplatesForStorefront = (locale) => {
  if (locale === "en_US") {
    return {
      verifyEmailTemplate: verifyEmailEnglish,
      resetPasswordMailTemplate: passwordResetEnglish,
    };
  } else if (locale === "ja_JP") {
    return {
      verifyEmailTemplate: verifyEmailJapanese,
      resetPasswordMailTemplate: passwordResetJapanese,
    };
  } else {
    return { verifyEmailTemplate: "", resetPasswordMailTemplate: "" };
  }
};

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown Browser";

  if (userAgent.includes("Chrome")) {
    browserName = "Google Chrome";
  } else if (userAgent.includes("Firefox")) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    browserName = "Safari";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
    browserName = "Internet Explorer";
  } else if (userAgent.includes("Edge")) {
    browserName = "Microsoft Edge";
  }

  return browserName;
};

export const getOSInfo = () => {
  const platform = navigator.platform;
  let osName = "Unknown OS";

  if (platform.startsWith("Win")) {
    osName = "Windows";
  } else if (platform.startsWith("Mac")) {
    osName = "macOS";
  } else if (platform.startsWith("Linux")) {
    osName = "Linux";
  } else if (/Android/.test(navigator.userAgent)) {
    osName = "Android";
  } else if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
    osName = "iOS";
  }

  return osName;
};

export const getBenefitTitleAndLink = (t, currentRecord) => {
  if (currentRecord) {
    const { type } = currentRecord;
    let title, link;

    if (type === "sweepstakes") {
      title = t("BENEFIT_TYPES.SWEEPSTAKES.TITLE");
      link = "sweepstakes";
    } else if (type === "coupon") {
      title = t("BENEFIT_TYPES.DISCOUNT_CODES.TITLE");
      link = "discount-codes";
    } else if (type === "video") {
      title = t("BENEFIT_TYPES.VIDEO.TITLE");
      link = "gated-video";
    } else if (type === "streams") {
      title = t("BENEFIT_TYPES.STREAMS.TITLE");
      link = "gated-streams";
    } else if (type === "audio") {
      title = t("BENEFIT_TYPES.AUDIO.TITLE");
      link = "gated-audio";
    } else if (type === "survey") {
      title = t("BENEFIT_TYPES.SURVEY.TITLE");
      link = "survey";
    } else {
      title = t("BENEFIT_TYPES.DIGITAL_BOOK.TITLE");
      link = "gated-ebook";
    }

    return [title, link];
  }
  return ["", ""];
};